import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Breadcrumb from "components/Breadcrumb";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "breadcrumb"
    }}>{`Breadcrumb`}</h1>

    <Playground __position={0} __code={'<Breadcrumb tag=\"nav\" listTag=\"div\">\n  <Breadcrumb.Item tag=\"a\" href=\"#\">\n    Home\n  </Breadcrumb.Item>\n  <Breadcrumb.Item tag=\"a\" href=\"#\">\n    Page\n  </Breadcrumb.Item>\n  <Breadcrumb.Item tag=\"a\" href=\"#\">\n    SubPage\n  </Breadcrumb.Item>\n  <Breadcrumb.Item active tag=\"span\">\n    Active Page\n  </Breadcrumb.Item>\n</Breadcrumb>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Breadcrumb,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Breadcrumb tag="nav" listTag="div" mdxType="Breadcrumb">
    <Breadcrumb.Item tag="a" href="#">Home</Breadcrumb.Item>
    <Breadcrumb.Item tag="a" href="#">Page</Breadcrumb.Item>
    <Breadcrumb.Item tag="a" href="#">SubPage</Breadcrumb.Item>
    <Breadcrumb.Item active tag="span">Active Page</Breadcrumb.Item>
  </Breadcrumb>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/breadcrumbs/" target="_blank">Reactstrap Breadcrumb</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      